export const routes = {
    admin: {
        index: '/admin',
    },
    contact: '/contact',
    createAccount: '/create-account',
    home: '/',
    terms: '/terms',
    privacyPolicy: '/privacy-policy',
};

export const apiRoutes = {
    contact: {
        vcard: {
            get: (slug: string) => `/api/contact/vcard/${slug}`,
        },
    },
};

export const externalRoutes = {
    contactUs: `mailto:dom@bigbyte.digital`,
    recaptcha: {
        url: 'https://www.google.com/recaptcha/api/siteverify',
    },
};
