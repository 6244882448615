'use client';

import classNames from 'classnames';
import { FC, PropsWithChildren, useRef, useState } from 'react';
import { Button, ButtonProps, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaRegCopy } from 'react-icons/fa';
import { useCopyToClipboard, useInterval } from 'usehooks-ts';

const DEFAULT_ICON_SIZE = 24;

export interface ContactButtonProps {
    className?: string;
    href?: string;
    Icon?: React.ReactElement;
    iconSize?: number;
    title?: string;
    variant?: ButtonProps['variant'];
    size?: ButtonProps['size'] | 'xs';
    onClick?: ButtonProps['onClick'];
    target?: ButtonProps['target'];
    copyToClipboard?: boolean | string;
}

export const ContactButton: FC<PropsWithChildren<ContactButtonProps>> = ({
    className,
    href,
    Icon,
    title,
    iconSize = DEFAULT_ICON_SIZE,
    size,
    variant,
    onClick,
    target = '',
    copyToClipboard = false,
    children,
}) => {
    const [, copyTextToClipboard] = useCopyToClipboard();

    const [showCopied, setShowCopied] = useState(false);

    useInterval(() => {
        setShowCopied(false);
    }, 5000);

    const copyButtonRef = useRef(null);
    let textToCopy: string | undefined;
    if (typeof copyToClipboard === 'string') {
        textToCopy = copyToClipboard;
    } else if (copyToClipboard && typeof children === 'string') {
        textToCopy = children;
    }

    return (
        <span className={className}>
            <Button
                href={href}
                title={title}
                className={classNames('contact-button', `btn-${size}`)}
                variant={variant}
                onClick={onClick}
                target={target}
            >
                {Icon && <Icon.type {...Icon.props} data-testid="icon" className="me-2" size={iconSize} />}
                {children}
            </Button>
            {textToCopy && (
                <>
                    <OverlayTrigger
                        show={showCopied}
                        placement="right"
                        trigger="click"
                        overlay={<Tooltip id="overlay">Copied to clipboard!</Tooltip>}
                    >
                        <Button
                            ref={copyButtonRef}
                            className="ms-2"
                            variant="link"
                            href="#"
                            onClick={(e): void => {
                                e.preventDefault();
                                setShowCopied(true);
                                void copyTextToClipboard(textToCopy || '');
                            }}
                            title={`Copy to clipboard`}
                        >
                            <FaRegCopy />
                        </Button>
                    </OverlayTrigger>
                </>
            )}
        </span>
    );
};
