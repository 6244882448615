'use client';

import { BlokLink } from '@components/storyblok/BlokLink';
import { storyblokEditable } from '@storyblok/react';
import { useSticky } from '@utils/hooks/useSticky';
import { SbStoryLink } from '@utils/storyblok/types';
import classNames from 'classnames';
import Image from 'next/image';
import { NavLink as BsNavLink, Container, Nav, Navbar } from 'react-bootstrap';
import { NavLink } from '../NavLink';

interface NavLinkBlok extends Blok {
    name: string;
    link: SbStoryLink;
}

export interface HeaderBlok extends Blok {
    title?: string;
    navLinks?: NavLinkBlok[];
    titleLink?: SbStoryLink;
}

export const Header: BlokFC<HeaderBlok> = ({ blok }) => {
    const [navRef, isStuck] = useSticky();

    const handleToggle = (): void => {
        window.scrollTo(0, 0);
    };

    const { title, titleLink, navLinks } = blok;

    return (
        <Navbar
            {...storyblokEditable(blok)}
            className={classNames('bg-white', isStuck ? 'stuck' : 'unstuck')}
            id="header-nav"
            expand="lg"
            role="navigation"
            aria-label="Main"
            sticky="top"
            ref={navRef}
        >
            <Container fluid>
                <Navbar.Toggle aria-controls="navbar-nav" onClick={handleToggle} />
                <Navbar.Brand className="">
                    <BlokLink link={titleLink}>
                        <BsNavLink as="span" aria-label={`${title || ''} Home`} className="d-flex align-items-center">
                            <Image
                                width={60}
                                height={60}
                                className="d-inline-block align-top"
                                alt="BigByte Digital Logo"
                                src="/bigbyte-logo-nav.svg"
                                priority
                            />

                            <span className="navbar-brand-title">{title}</span>
                        </BsNavLink>
                    </BlokLink>
                </Navbar.Brand>
                <Navbar.Collapse id="navbar-nav">
                    <Nav className="me-auto ms-auto">
                        {navLinks?.map((navLinkBlok: NavLinkBlok) => (
                            <NavLink key={navLinkBlok._uid} blok={navLinkBlok} />
                        ))}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Header;
