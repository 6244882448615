'use client';

import { submitContactForm } from '@api/nextApi';
import { LoadingSpinner, SPINNER_COLOR_WHITE } from '@components/LoadingSpinner';
import { ReCaptchaProvider, useReCaptcha } from '@lib/recaptcha';
import { Formik } from 'formik';
import Link from 'next/link';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { FaCheckCircle } from 'react-icons/fa';
import * as yup from 'yup';
import { ContactFormValues } from './types';

const DEFAULT_MESSAGE_ROWS = 5;
export interface ContactFormBlok extends Blok {
    messageRows?: number;
    autoFocus?: boolean;
}

const schema: yup.Schema<ContactFormValues> = yup.object().shape({
    name: yup.string().required('Please provide your name'),
    email: yup
        .string()
        .trim()
        .email("Please provide a valid email address (eg. 'you@example.com')")
        .required("Please provide an email address (eg. 'you@example.com')"),
    company: yup.string(),
    phone: yup.string(),
    message: yup.string().required('Please leave us a message'),
});

export const ContactForm: BlokFC<ContactFormBlok> = ({ blok }) => {
    const [isSuccess, setIsSuccess] = useState<boolean | undefined>(undefined);
    const [isSendEnabled, setIsSendEnabled] = useState<boolean>(true);
    const { executeRecaptcha } = useReCaptcha();

    const { messageRows, autoFocus } = blok;

    const nameField = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (!!autoFocus && nameField.current) {
            // Browsers don't seem to handle focus input in SPAs
            nameField.current.focus();
        }
    }, [autoFocus]);

    const handleFormSubmit = async (values: ContactFormValues): Promise<void> => {
        const token = await executeRecaptcha('form_submit');

        try {
            setIsSendEnabled(false);
            await submitContactForm(values, token);
            setIsSuccess(true);
        } catch {
            setIsSuccess(false);
            setIsSendEnabled(true);
        }
    };

    return (
        <ReCaptchaProvider useEnterprise>
            <Formik
                validationSchema={schema}
                onSubmit={handleFormSubmit}
                initialValues={{
                    name: '',
                    email: '',
                    company: '',
                    phone: '',
                    message: '',
                }}
            >
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    errors,
                    isSubmitting,
                }): React.ReactElement => {
                    const interceptBlur = (e: React.FocusEvent<Element>): void => {
                        setIsSendEnabled(true);
                        handleBlur(e);
                    };

                    const interceptChange = (e: React.ChangeEvent<Element>): void => {
                        setIsSendEnabled(true);
                        handleChange(e);
                    };

                    return (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Group controlId="name">
                                <Form.Label className="fw-bold">Name *</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    ref={nameField}
                                    value={values.name}
                                    onChange={interceptChange}
                                    onBlur={interceptBlur}
                                    isValid={!!touched.name && !errors.name}
                                    isInvalid={!!touched.name && !!errors.name}
                                />
                                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="email">
                                <Form.Label className="fw-bold mt-4">Email *</Form.Label>
                                <InputGroup hasValidation>
                                    <Form.Control
                                        type="text"
                                        name="email"
                                        value={values.email}
                                        onChange={interceptChange}
                                        onBlur={interceptBlur}
                                        isValid={!!touched.email && !errors.email}
                                        isInvalid={!!touched.email && !!errors.email}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group controlId="company">
                                <Form.Label className="fw-bold mt-4">Company</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="company"
                                    value={values.company}
                                    onChange={interceptChange}
                                    onBlur={interceptBlur}
                                    isValid={!!touched.company && !errors.company}
                                    isInvalid={!!touched.company && !!errors.company}
                                />

                                <Form.Control.Feedback type="invalid">{errors.company}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="phone">
                                <Form.Label className="fw-bold mt-4">Phone</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="phone"
                                    value={values.phone}
                                    onChange={interceptChange}
                                    onBlur={interceptBlur}
                                    isValid={!!touched.phone && !errors.phone}
                                    isInvalid={!!touched.phone && !!errors.phone}
                                />

                                <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="message">
                                <Form.Label className="fw-bold mt-4">How can we help you? *</Form.Label>
                                <Form.Control
                                    type="text"
                                    as="textarea"
                                    rows={messageRows || DEFAULT_MESSAGE_ROWS}
                                    name="message"
                                    value={values.message}
                                    onChange={interceptChange}
                                    onBlur={handleBlur}
                                    isValid={!!touched.message && !errors.message}
                                    isInvalid={!!touched.message && !!errors.message}
                                />

                                <Form.Control.Feedback type="invalid">{errors.message}</Form.Control.Feedback>
                            </Form.Group>
                            <div className="text-center">
                                <Button
                                    type="submit"
                                    variant="secondary"
                                    className="w-50 mt-5 fw-bold"
                                    disabled={!!isSubmitting || !isSendEnabled}
                                >
                                    <div>
                                        {!isSubmitting ? (
                                            !isSendEnabled ? (
                                                <>
                                                    <span className="pe-2">Sent</span>
                                                    <FaCheckCircle size="20" />
                                                </>
                                            ) : (
                                                'Send'
                                            )
                                        ) : (
                                            <LoadingSpinner color={SPINNER_COLOR_WHITE} />
                                        )}
                                    </div>
                                </Button>
                                {isSuccess === true && (
                                    <div className="pt-3">
                                        <span className="text-success pe-2">
                                            <FaCheckCircle size="35"></FaCheckCircle>
                                        </span>
                                        Sent! We&apos;ll be in touch soon.
                                    </div>
                                )}

                                {isSuccess === false && (
                                    <div className="pt-2 text-danger">
                                        Something went wrong. Please email us directly at{' '}
                                        <Button variant="link" href="mailto:contact@bigbyte.digital">
                                            contact@bigbyte.digital
                                        </Button>
                                    </div>
                                )}
                                <div className="small pt-5">
                                    By clicking the &quot;Send&quot; button you agree to our{' '}
                                    <Link href="/privacy-policy">Privacy Policy</Link>.
                                </div>
                                <div className="small pt-2">
                                    This site is protected by reCAPTCHA and the{' '}
                                    <a href="https://policies.google.com/privacy">Google Privacy Policy</a> and{' '}
                                    <a href="https://policies.google.com/terms">Google Terms of Service</a> apply.
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </ReCaptchaProvider>
    );
};

export default ContactForm;
