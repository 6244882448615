import { SbStoryLink as SbStoryLink } from '@utils/storyblok/types';
import { validateEmail } from '@utils/text';
import { UrlObject } from 'url';

export type LinkToUrl = (link: SbStoryLink) => UrlObject;

export const linkAsUrl: LinkToUrl = (link) => {
    const urlObject: UrlObject = {};

    const { anchor, linktype, story } = link;
    const url = link.url || link.href;
    if (linktype == 'story') {
        if (story || link.href) {
            const storyUrl = story?.url;
            if (storyUrl) {
                urlObject.pathname = `/${storyUrl.replace(/^\/$/g, '')}`;
                if (anchor) {
                    urlObject.hash = anchor;
                }
            }
        }
    } else if ((linktype == 'url' || linktype == 'asset') && typeof url != undefined) {
        const splitUrl = url?.split('#');
        if (splitUrl) {
            if (splitUrl.length > 1) {
                urlObject.hash = splitUrl[1];
            }
            urlObject.pathname = splitUrl[0];
        } else {
            urlObject.pathname = url;
        }
    } else if (linktype == 'email' && typeof url != undefined) {
        urlObject.pathname = `mailto:${url || ''}`;
    } else {
        throw new Error(`Unsupported link type: ${linktype || 'unknown'}`);
    }

    return urlObject;
};

export const isValidLink = (link?: SbStoryLink): boolean => {
    if (link) {
        const { linktype, story, cached_url } = link;
        const url = link.url || link.href;

        if (linktype === 'story') {
            // The story's URL is cached in edit mode where story lookup is not provided
            return !!(story && (story.url || cached_url || url));
        } else if (linktype == 'url' || linktype == 'asset') {
            return !!url;
        } else if (linktype === 'email') {
            return !!url && validateEmail(url);
        } else {
            throw new Error(`Unsupported link type: ${linktype || 'unknown'}`);
        }
    }
    return false;
};
