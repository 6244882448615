import { AxiosResponse } from 'axios';
import { api } from './api';
import { ContactFormValues } from 'src/app/api/contact/types';

export const submitContactForm = async (values: ContactFormValues, token: string): Promise<AxiosResponse> => {
    return api.post('/api/contact', {
        values: {
            ...values,
            email: values.email.trim(),
        },
        token,
    });
};
