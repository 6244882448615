export { v4 as uuid } from 'uuid';

export const isDevelopment = (): boolean => process.env.NODE_ENV === 'development';

export const isForcedPreview = (): boolean => process.env.NEXT_PUBLIC_STORYBLOK_FORCE_PREVIEW === 'true';

export const isPreview = (preview: boolean): boolean => (preview && isDevelopment()) || isForcedPreview();

export const isServerSide = (): boolean => typeof window === 'undefined';

export const isDraft = (): boolean => process.env.STORYBLOK_VERSION === 'draft';

export const getRecaptchaSiteKey = (): string => process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || '';

export const getRecaptchaSecretKey = (): string => process.env.RECAPTCHA_SECRET_KEY || '';
