import { BlokLink } from '@components/storyblok/BlokLink';
import { storyblokEditable } from '@storyblok/react';
import { SbStoryLink } from '@utils/storyblok/types';
import { NavLink as BsNavLink } from 'react-bootstrap';

export interface NavLinkProps {
    name?: string;
    link?: SbStoryLink;
}

export type NavLinkBlok = Blok & NavLinkProps;

export const NavLink: BlokFC<NavLinkBlok> = ({ blok }) => {
    const { name, link } = blok;

    return (
        <BlokLink link={link}>
            <BsNavLink as="span" {...storyblokEditable(blok)}>
                {name}
            </BsNavLink>
        </BlokLink>
    );
};

export default NavLink;
