'use client';

import { apiRoutes } from '@constants/routes';
import { FC, PropsWithChildren, ReactNode } from 'react';
import { TiContacts } from 'react-icons/ti';
import { ContactButton, ContactButtonProps } from './ContactButton';

export interface VCardButtonProps {
    buttonProps: ContactButtonProps;
    meSlug: string;
    children: ReactNode;
}

export const VCardButton: FC<PropsWithChildren<VCardButtonProps>> = ({ buttonProps, meSlug, children }) => {
    const downloadVcardHref = apiRoutes.contact.vcard.get(meSlug);

    const contactButtonProps: ContactButtonProps = {
        href: downloadVcardHref,
        variant: 'primary',
        Icon: <TiContacts />,
        ...buttonProps,
    };
    return <ContactButton {...contactButtonProps}>{children}</ContactButton>;
};
