'use client';

import { RefObject, useEffect, useRef, useState } from 'react';

export const useSticky = <T extends HTMLElement>(): readonly [RefObject<T>, boolean] => {
    const stickyRef = useRef<T>(null);
    const [isStuck, setIsStuck] = useState(false);

    useEffect(() => {
        const handleChange = (): void => {
            if (!stickyRef.current) {
                return;
            }

            const bounds = stickyRef.current.getBoundingClientRect();
            const stickyActive = bounds.top < window.scrollY;

            if (stickyActive && !isStuck) {
                setIsStuck(true);
            } else if (!stickyActive && isStuck) {
                setIsStuck(false);
            }
        };

        handleChange();

        document.addEventListener('scroll', handleChange);
        window.addEventListener('resize', handleChange);
        window.addEventListener('orientationchange', handleChange);

        return (): void => {
            document.removeEventListener('scroll', handleChange);
            window.removeEventListener('resize', handleChange);
            window.removeEventListener('orientationchange', handleChange);
        };
    }, [isStuck]);

    return [stickyRef, isStuck] as const;
};
