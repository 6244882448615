import React from 'react';
import { Triangle } from 'react-loader-spinner';

export interface LoadingSpinnerProps {
    size?: number;
    color?: string;
}

export const SPINNER_COLOR_DEFAULT = '#7839AC';
export const SPINNER_COLOR_WHITE = '#ffffff';

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ size = 30, color = SPINNER_COLOR_DEFAULT }) => {
    return (
        <div data-testid="loading-spinner" className="loading-spinner w-100 text-center" role="progressbar">
            <Triangle height={size} width={size} color={color} wrapperClass="" />
            <span data-testid="loading-spinner-accessible" className="visually-hidden">
                Loading...
            </span>
        </div>
    );
};

export default LoadingSpinner;
