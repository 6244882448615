import { isDevelopment } from '@utils/index';
import { linkAsUrl } from '@utils/storyblok/link';
import { SbStoryLink } from '@utils/storyblok/types';
import Link, { LinkProps } from 'next/link';
import React, { PropsWithChildren } from 'react';

export interface Props {
    link?: SbStoryLink;
    target?: string;
    legacyBehavior?: boolean;
    linkProps?: LinkProps;
}

export const BlokLink: React.FC<PropsWithChildren<Props>> = ({ children, link, target, legacyBehavior, linkProps }) => {
    if (!link) {
        if (isDevelopment()) {
            return <>{children}</>;
        }
        throw new Error('Invalid link');
    }

    const href = linkAsUrl(link);
    const nextLinkProps = {
        href,
        passHref: legacyBehavior,
        target: link.target || target,
        legacyBehavior,
        ...linkProps,
    };

    return (
        <>
            <Link {...nextLinkProps} className="next-link">
                {children}
            </Link>
        </>
    );
};

export default BlokLink;
